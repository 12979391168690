<!-- 我们的团队 -->
<template>
  <div class="about_team" v-once>
    <div class="title">我们的团队</div>
    <div class="advantages">
      <div class="line">
        <div
          class="item"
          v-for="(item, index) in info.slice(0, 2)"
          :key="index"
        >
          <img :src="item.icon" alt="" />
          <div class="title2">{{ item.title }}</div>
          <div class="desc">
            <div v-for="(txt, num) in item.desc" :key="num">
              {{ txt }}
            </div>
          </div>
        </div>
      </div>
      <div class="line" style="margin-top:77px">
        <div
          class="item"
          v-for="(item, index) in info.slice(2, 4)"
          :key="index"
        >
          <img :src="item.icon" alt="" />
          <div class="title2">{{ item.title }}</div>
          <div class="desc">
            <div v-for="(txt, num) in item.desc" :key="num">
              {{ txt }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const info = [
  {
    icon: require("../../assets/about/team_0.png"),
    title: "Lina",
    desc: ["资深优化师", "6年从业经验"]
  },
  {
    icon: require("../../assets/about/team_1.png"),
    title: "Jacky",
    desc: ["高级视频设计师", "5年游戏广告设计"]
  },
  {
    icon: require("../../assets/about/team_2.png"),
    title: "Solly",
    desc: ["高级平面设计师", "8年平面设计"]
  },
  {
    icon: require("../../assets/about/team_3.png"),
    title: "Stella",
    desc: ["商务总监", "从业8年，客户资源丰富"]
  }
];
export default {
  name: "about_team",
  components: {},
  data() {
    return {
      info: info
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../common/scss/function.scss";
.about_team {
  width: 100%;
  max-width: 1600px;

  .title {
    font-family: PingFangSC-Medium;
    font-size: 50px;
    font-weight: 600;
    color: #04101d;
    letter-spacing: 0;
    text-align: center;
    margin: 130px auto 82px auto;
  }
  .advantages {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .line {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .item {
      width: 370px;
      height: 370px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background: #ffffff;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      img {
        width: 370px;
        height: 160px;
      }
      .title2 {
        margin: 62px auto 17px auto;
        font-weight: 500;
        font-size: 28px;
        color: #191919;
        letter-spacing: 0;
      }
      .desc {
        font-weight: 500;
        font-size: 20px;
        color: #838797;
        line-height: 26px;
      }
      &:last-child {
        margin-left: 138px;
      }
    }
  }
}
</style>
