<template>
  <div class="link">
    <Page :src="src" :info="info"></Page>
    <Connect></Connect>
  </div>
</template>

<script>
import Page from "../components/page";
import Connect from "./link/connect";

export default {
  name: "link",
  data() {
    return {
      src: require("../assets/link.jpg"),
      info: [
        "联系我们",
        "如有任何疑问，我们7*24小时竭诚为您服务",
        "在线QQ客服。 广告行业制作。关注官方微信"
      ]
    };
  },
  components: { Page, Connect }
};
</script>
<style lang="scss" scoped>
.link {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
