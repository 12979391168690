<!-- 联系我们 -->
<template>
  <div class="link_connect" v-once>
    <div class="title">联系我们</div>
    <div class="connect">
      <div class="line">
        <img src="../../assets/link/connect.png" alt="" />
        <div class="info">
          <p>地址：中国（上海）自由贸易试验区世纪大道1777号6层B室</p>
          <p>联系电话：008613263266406</p>
          <p>工作时间： 9am-9pm</p>
          <p>邮箱：brian@uamax.net</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "link_connect",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../common/scss/function.scss";
.link_connect {
  width: 100%;

  .title {
    font-family: PingFangSC-Medium;
    font-size: 50px;
    font-weight: 600;
    color: #04101d;
    letter-spacing: 0;
    text-align: center;
    margin: 130px auto 82px auto;
  }
  .connect {
    height: 683px;
    margin-bottom: 256px;
    width: 100%;
    background: rgba(2, 121, 255, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    .line {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1200px;
    }
    img {
      width: 375px;
    }
    .info {
      padding-left: 160px;
      text-align: left;
      width: 611px;
      font-size: 36px;
      color: #333333;
      line-height: 55px;
      font-weight: 600;
    }
  }
}
</style>
