<!-- 案例 -->
<template>
  <div class="about_case" v-once>
    <div class="title">案例欣赏</div>
    <div class="case">
      <div class="line" style="margin-bottom: 16px">
        <div class="card"><img :src="info[0]" /></div>
        <div class="card" style=" margin-left: 170px">
          <img :src="info[1]" />
        </div>
        <div class="card" style=" margin-left: 170px">
          <img :src="info[2]" />
        </div>
      </div>
      <div class="line" style="margin-top:120px">
        <div class="card"><img :src="info[3]" style="" /></div>
        <div class="card" style=" margin-left: 170px">
          <img :src="info[4]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const info = [
  require("../../assets/about/case_0.png"),
  require("../../assets/about/case_1.png"),
  require("../../assets/about/case_2.png"),
  require("../../assets/about/case_3.png"),
  require("../../assets/about/case_4.png")
];
export default {
  name: "about_case",
  components: {},
  data() {
    return {
      info: info
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../common/scss/function.scss";
.about_case {
  width: 100%;
  max-width: 1600px;
  .title {
    font-family: PingFangSC-Medium;
    font-size: 50px;
    font-weight: 600;
    color: #04101d;
    letter-spacing: 0;
    text-align: center;
    margin: 130px auto 82px auto;
  }
  .case {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .line {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .card {
        width: 300px;
        height: 520px;
        background: #ffffff;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        img {
          padding-top: 15px;
          width: 275px;
          height: 489px;
        }
      }
    }
  }
}
</style>
