<template>
  <div class="about">
    <Page :src="src" :info="info"></Page>
    <Case></Case>
    <Team></Team>
    <Footer></Footer>
  </div>
</template>

<script>
import Page from "../components/page";
import Case from "./about/cases";
import Team from "./about/team";
import Footer from "./home/footer";
export default {
  name: "About",
  data() {
    return {
      src: require("../assets/about.jpg"),
      info: [
        "UAmax是北京快乐茄信息技术有限公司上海分公司在代理facebook投放上新成立的服务品牌，一家集科技，服务，数据与一体的高新科技公司，帮助客户制作素材，优化投放数据；",
        "多年深耕互联网领域，出海欧美，东南亚，中东北非是公司独特优势和核心竞争力。",
        "UAmax连续多年被认定为国家规划布局内重点软件企业，优质企业、重信用守合同企业"
      ]
    };
  },
  components: { Page, Case, Team, Footer }
};
</script>
<style lang="scss" scoped>
.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
